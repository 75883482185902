// packages
import * as React from "react";
// components
import { PolitikHeading, PolitikParagraph } from "../Typography";
import { SectionContainer, Graphic } from "./PolitikStyles";
// media
import collapse from "../../images/Collapse.png";

const IntroSection = () => (
  <SectionContainer>
    <PolitikHeading>The World of Politik</PolitikHeading>
    <PolitikParagraph>
      Politik is a dynamic strategy game of military, political, and corporate control for 2–6
      players. Representing any of 12 nations, players develop distinct identities during their
      struggle for global supremacy. They seize territories, orchestrate propaganda campaigns, and
      pilot companies to conquer markets. 500+ unique cards provide the landscapes, propaganda,
      companies, assets, and events for each nation to forge its path to power.
    </PolitikParagraph>
    <PolitikParagraph>
      Following the Great Collapse, the world is fragmented but finding its way after decades of
      Imperial disinformation. Ascending from those ashes, pirate signals entangle the airwaves.
      Deserted broadcast stations spark back to life, trumpeting new anthems. With right and wrong
      blurred, a disoriented world yearns for truth. And one nation will decide it.
    </PolitikParagraph>
    <Graphic src={collapse} alt="A fallen statue symbolizing a regime being toppled." />
  </SectionContainer>
);

export default IntroSection;
