/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// packages
import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";
// components
import Theme from "./Theme";
import NavBar from "./NavBar";
// styles
import "../css/layout.css";
// constants
import { PAGES } from "../constants";
// functions
import { mediaQuery } from "./Theme";

const SiteWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  ${props =>
    props.page === PAGES.POLITIK_PAGE &&
    css`
      background-color: ${props => props.theme.colors.politikWhite};
    `};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 6rem 1.5rem 4rem 1.5rem;

  ${mediaQuery("margin", "", [{ 800: "6rem 4rem 4rem 4rem" }, { 1024: "4rem 4rem 4rem 19rem" }])};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 50rem;
`;

const MainLayout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Theme>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/qdb5xjb.css" />
      </Helmet>
      <SiteWrapper page={page}>
        <NavBar siteTitle={data.site.siteMetadata?.title || `Title`} page={page} />
        <ContentContainer>
          <ContentWrapper>
            <main>{children}</main>
          </ContentWrapper>
        </ContentContainer>
      </SiteWrapper>
    </Theme>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default MainLayout;
