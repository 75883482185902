// packages
import * as React from "react";
import styled from "styled-components";
// components
import { PolitikHeading, PolitikParagraph } from "../Typography";
import {
  SectionContainer,
  SingleIconsWrapper,
  SingleIconContainer,
  Icon,
  Label,
  Graphic
} from "./PolitikStyles";
// functions
import { mediaQuery } from "../Theme";
// media
import BaseEnergyIcon from "../../images/svgs/energy-icon.svg";
import BaseTechnologyIcon from "../../images/svgs/technology-icon.svg";
import BaseMediaIcon from "../../images/svgs/media-icon.svg";
import BaseFinanceIcon from "../../images/svgs/finance-icon.svg";
import BaseHumanitiesIcon from "../../images/svgs/humanities-icon.svg";
import BaseManufacturingIcon from "../../images/svgs/manufacturing-icon.svg";
import newElm from "../../images/New_Elm.png";

const EnergyIcon = styled(BaseEnergyIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const FinanceIcon = styled(BaseFinanceIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const HumanitiesIcon = styled(BaseHumanitiesIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const ManufacturingIcon = styled(BaseManufacturingIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const MediaIcon = styled(BaseMediaIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const TechnologyIcon = styled(BaseTechnologyIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const IndustrySingleIconsWrapper = styled(SingleIconsWrapper)`
  padding: 0;

  ${mediaQuery("padding", "", [
    { 400: "0 2rem" },
    { 500: "0 1.5rem" },
    { 600: "0 4rem" },
    { 700: "0 7rem" },
    { 900: 0 }
  ])}
`;

const IndustrySection = () => {
  const energyIcon = <EnergyIcon height="4rem" />;
  const financeIcon = <FinanceIcon height="4rem" />;
  const humanitiesIcon = <HumanitiesIcon height="4rem" />;
  const manufacturingIcon = <ManufacturingIcon height="4rem" />;
  const mediaIcon = <MediaIcon height="4rem" />;
  const technologyIcon = <TechnologyIcon height="4rem" />;
  const industryIcons = [
    {
      name: "Energy",
      component: energyIcon
    },
    {
      name: "Finance",
      component: financeIcon
    },
    {
      name: "Humanities",
      component: humanitiesIcon
    },
    {
      name: "Manufacturing",
      component: manufacturingIcon
    },
    {
      name: "Media",
      component: mediaIcon
    },
    {
      name: "Technology",
      component: technologyIcon
    }
  ];

  const mappedIndustryIcons = industryIcons.map((icon, index) => (
    <SingleIconContainer key={icon.name + index}>
      <Icon>{icon.component}</Icon>
      <Label>{icon.name}</Label>
    </SingleIconContainer>
  ));

  return (
    <SectionContainer>
      <PolitikHeading>Industries</PolitikHeading>
      <PolitikParagraph>
        Competition for market share in Politik is fierce and high-stakes. There are six industries
        in Politik: energy, finance, humanities, manufacturing, media, and technology. Each hosts a
        battle for superiority. Depending on the current economic landscape, new market share may be
        available in specified industries. However, in constrained bear landscapes, industry markets
        shrink.
      </PolitikParagraph>
      <PolitikParagraph>
        Better your nation’s position in the corporate arena by controlling the most market in an
        industry. By doing so, you’ll gain income bonuses and be closer to claiming a corporate
        power grab.
      </PolitikParagraph>
      <IndustrySingleIconsWrapper>{mappedIndustryIcons}</IndustrySingleIconsWrapper>
      <Graphic src={newElm} alt="The NewElm Headquarters" />
    </SectionContainer>
  );
};

export default IndustrySection;
