// packages
import styled from "styled-components";
// components
import { PolitikParagraph } from "../Typography";
// functions
import { mediaQuery } from "../Theme";
// media
import background from "../../images/Card_Background_RGB_600.jpg";

export const SectionContainer = styled.div`
  width: 100%;
  background: url(${background}) no-repeat center;
  background-size: cover;
  overflow: hidden;
  margin-top: 3rem;
  padding: 2rem 1rem;
  border-radius: 1rem;

  ${mediaQuery("padding", "rem", [{ 500: 2 }])};
`;

export const SingleIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const SingleIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 6rem;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: auto;
  min-width: 6rem;
  height: 4rem;
`;

export const Label = styled(PolitikParagraph)`
  margin: 0.5rem 0 0 0;
`;

export const Graphic = styled.img`
  width: 100%;
  margin: 2rem 0 0 0;
`;
