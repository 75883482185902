// packages
import styled from "styled-components";

// main site
export const MainHeading = styled.h1`
  color: ${props => props.theme.colors.byBlack};
  margin-bottom: 4rem;
`;

export const SubHeading = styled.h2`
  color: ${props => props.theme.colors.byBlack};
  margin-bottom: 2rem;
`;

export const ParagraphContainer = styled.div`
  margin-bottom: 2rem;
`;

export const LeadingParagraph = styled.p`
  color: ${props => props.theme.colors.byBlack};
  &::first-letter {
    float: left;
    font-family: ${props => props.theme.fonts.header};
    font-size: 5.25rem;
    line-height: 1;
    padding: 0.25rem 0.5rem;
    margin-right: 0.25rem;
    background-color: ${props => props.theme.colors.iceBlue};
  }
`;

export const StyledParagraph = styled.p`
  color: ${props => props.theme.colors.byBlack};
`;

// politik page
export const PolitikHeading = styled.h1`
  font-family: ${props => props.theme.fonts.reross};
  font-weight: ${props => props.theme.fontWeights.reross};
  font-size: 2.5rem;
  color: ${props => props.theme.colors.politikBlack};
  border-bottom: 1px solid #a49c7a;
`;

export const PolitikParagraph = styled.p`
  font-family: ${props => props.theme.fonts.futura};
  font-size: 1rem;
  font-weight: ${props => props.theme.fontWeights.futuraBook};
  color: ${props => props.theme.colors.politikBlack};
`;
