// packages
import * as React from "react";
import styled from "styled-components";
// components
import { PolitikHeading, PolitikParagraph } from "../Typography";
import { SectionContainer, Icon, Label, Graphic } from "./PolitikStyles";
// functions
import { mediaQuery } from "../Theme";
// media
import BaseCorporateIcon from "../../images/svgs/corporate-icon.svg";
import BaseCorporateVictoryIcon from "../../images/svgs/corporate-victory-icon.svg";
import BaseMilitaryIcon from "../../images/svgs/military-icon.svg";
import BaseMilitaryVictoryIcon from "../../images/svgs/military-victory-icon.svg";
import BasePoliticalIcon from "../../images/svgs/political-icon.svg";
import BasePoliticalVictoryIcon from "../../images/svgs/political-victory-icon.svg";
import manifestDestiny from "../../images/Manifest_Destiny.png";

const CorporateIcon = styled(BaseCorporateIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const CorporateVictoryIcon = styled(BaseCorporateVictoryIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const MilitaryIcon = styled(BaseMilitaryIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const MilitaryVictoryIcon = styled(BaseMilitaryVictoryIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const PoliticalIcon = styled(BasePoliticalIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const PoliticalVictoryIcon = styled(BasePoliticalVictoryIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const VictoryIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

const ConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

const VictoryIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VictorySection = () => {
  const corporateIcon = <CorporateIcon height="4rem" />;
  const corporateVictoryIcon = <CorporateVictoryIcon height="4rem" />;
  const militaryIcon = <MilitaryIcon height="4rem" />;
  const militaryVictoryIcon = <MilitaryVictoryIcon height="4rem" />;
  const politicalIcon = <PoliticalIcon height="4rem" />;
  const politicalVictoryIcon = <PoliticalVictoryIcon height="4rem" />;
  const victoryIcons = [
    {
      name: "Corporate",
      icon: corporateIcon,
      victoryIcon: corporateVictoryIcon
    },
    {
      name: "Military",
      icon: militaryIcon,
      victoryIcon: militaryVictoryIcon
    },
    {
      name: "Political",
      icon: politicalIcon,
      victoryIcon: politicalVictoryIcon
    }
  ];

  const mappedVictoryIcons = victoryIcons.map((condition, index) => (
    <ConditionContainer key={condition.name + index}>
      <VictoryIconContainer>
        <Icon>{condition.icon}</Icon>
        <Icon>{condition.victoryIcon}</Icon>
      </VictoryIconContainer>
      <Label>{condition.name}</Label>
    </ConditionContainer>
  ));

  return (
    <SectionContainer>
      <PolitikHeading>Three Paths to Victory</PolitikHeading>
      <PolitikParagraph>
        Politik is a game about control and majorities—culminating in Power Grabs. Nations compete
        over three types of Power Grabs: Military, Political, and Corporate. Ruling Regions yields
        Military Power Grabs. Securing Council Seats yields Political Power Grabs. Dominating
        Industries yields Corporate Power Grabs. Nations must earn a combination of Power Grabs to
        win.
      </PolitikParagraph>
      <PolitikParagraph>
        Along the road to victory, each Power Grab offers distinct opportunities. Controlling states
        on the map increases resource production and card draw. Holding seats on the council adds
        game-changing benefits and abilities. Running powerful companies boosts income. To gain
        further advantage, corrupt Nations take on Obligations in exchange for rare and powerful
        effects. But be wary, Corruption carries a heavy price.
      </PolitikParagraph>
      <VictoryIconsWrapper>{mappedVictoryIcons}</VictoryIconsWrapper>
      <Graphic
        src={manifestDestiny}
        alt="A statuesque figure holding a sword upright emblazoned with rays and clouds."
      />
    </SectionContainer>
  );
};

export default VictorySection;
