// packages
import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
// components
import MainLayout from "../components/MainLayout";
import Seo from "../components/Seo";
import IntroSection from "../components/politik/IntroSection";
import VictorySection from "../components/politik/VictorySection";
import IndustrySection from "../components/politik/IndustrySection";
import PolitikNewsletterSignup from "../components/politik/PolitikNewsletterSignup";
// media
import cube from "../images/cube.png";
import prism from "../images/prism.png";
import sphere from "../images/sphere.png";
import PoliticalBaseSection from "../components/politik/PoliticalBaseSection";
import politikLogo from "../images/Politik_Logo.png";
// constants
import { PAGES } from "../constants";
// functions
import { mediaQuery } from "../components/Theme";

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const BackgroundGeometryContainer = styled.div`
  display: none;
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 50rem;

  ${mediaQuery("display", "", [{ 1024: "block" }])};
`;

const ForegroundGeometryContainer = styled.div`
  display: none;
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 50rem;
  z-index: 2;

  ${mediaQuery("display", "", [{ 1024: "block" }])};
`;

const Prism = styled.img.attrs(props => ({
  style: {
    transform: `translateY(${props.offset * 0.1}%)`
  }
}))`
  position: absolute;
  top: 10%;
  left: -6rem;
  width: 8rem;
`;

const Cube = styled.img.attrs(props => ({
  style: {
    transform: `translateY(-${props.offset * 0.04}%)`
  }
}))`
  position: absolute;
  top: 27.5%;
  right: 6rem;
  width: 10rem;

  ${mediaQuery("right", "rem", [{ 1150: -0 }, { 1200: -2 }, { 1280: -5 }])};
`;

const Sphere = styled.img.attrs(props => ({
  style: {
    transform: `translateY(-${props.offset * 0.04}%)`
  }
}))`
  position: absolute;
  bottom: 7.5%;
  right: 6rem;
  width: 10rem;

  ${mediaQuery("right", "rem", [{ 1150: -0 }, { 1200: -2 }, { 1280: -5 }])};
`;

const ScrollContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const NewsletterContainer = styled.div`
  position: relative;
  margin-top: 4rem;
  z-index: 3;
`;

const Blurb = styled.p`
  font-family: ${props => props.theme.fonts.futura};
  text-align: center;
  width: 100%;
`;

const Politik = () => {
  const [offset, setOffset] = useState(0);

  const handleScroll = () => {
    setOffset(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <MainLayout page={PAGES.POLITIK_PAGE}>
      <Seo title="Politik" />
      <BackgroundGeometryContainer>
        <Cube src={cube} alt="cube" offset={offset} />
        <Sphere src={sphere} alt="sphere" offset={offset} />
      </BackgroundGeometryContainer>
      <ForegroundGeometryContainer>
        <Prism src={prism} alt="prism" offset={offset} />
      </ForegroundGeometryContainer>
      <ScrollContainer>
        <LogoContainer>
          <img src={politikLogo} alt="The Politik Logo" />
        </LogoContainer>
        <IntroSection />
        <VictorySection />
        <PoliticalBaseSection />
        <IndustrySection />
      </ScrollContainer>
      <NewsletterContainer>
        <Blurb>
          Sign up to receive updates on Politik, its development, and other Black Yeti news.
        </Blurb>
        <PolitikNewsletterSignup />
      </NewsletterContainer>
    </MainLayout>
  );
};

export default Politik;
