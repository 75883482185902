// packages
import * as React from "react";
import styled from "styled-components";
// components
import { PolitikHeading, PolitikParagraph } from "../Typography";
import {
  SectionContainer,
  SingleIconsWrapper,
  SingleIconContainer,
  Icon,
  Label,
  Graphic
} from "./PolitikStyles";
// functions
import { mediaQuery } from "../Theme";
// media
import BaseCapitalismIcon from "../../images/svgs/capitalism-icon.svg";
import BaseCommunismIcon from "../../images/svgs/communism-icon.svg";
import BaseFascismIcon from "../../images/svgs/fascism-icon.svg";
import BaseStatismIcon from "../../images/svgs/statism-icon.svg";
import patriots from "../../images/Patriots.png";

const CapitalismIcon = styled(BaseCapitalismIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const CommunismIcon = styled(BaseCommunismIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const FascismIcon = styled(BaseFascismIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const StatismIcon = styled(BaseStatismIcon)`
  height: 3rem;

  ${mediaQuery("height", "rem", [{ 1024: 4 }])};
`;

const BaseSingleIconsWrapper = styled(SingleIconsWrapper)`
  padding: 0 1rem;

  ${mediaQuery("padding", "", [
    { 400: "0 3.5rem" },
    { 500: "0 4rem" },
    { 600: 0 },
    { 900: "0 6rem" },
    { 1024: "0 2.9rem" },
    { 1200: "0 6rem" }
  ])};
`;

const PoliticalBaseSection = () => {
  const capitalismIcon = <CapitalismIcon />;
  const communismIcon = <CommunismIcon />;
  const fascismIcon = <FascismIcon />;
  const statismIcon = <StatismIcon />;
  const baseIcons = [
    {
      name: "Capitalism",
      component: capitalismIcon
    },
    {
      name: "Communism",
      component: communismIcon
    },
    {
      name: "Fascism",
      component: fascismIcon
    },
    {
      name: "Statism",
      component: statismIcon
    }
  ];

  const mappedBaseIcons = baseIcons.map((icon, index) => (
    <SingleIconContainer key={icon.name + index}>
      <Icon>{icon.component}</Icon>
      <Label>{icon.name}</Label>
    </SingleIconContainer>
  ));

  return (
    <SectionContainer>
      <PolitikHeading>Political Bases</PolitikHeading>
      <PolitikParagraph>
        The nations of Politik establish their political identity through ideologies represented by
        propaganda. Each propaganda card belongs to one or more political bases: capitalism,
        communism, fascism, and statism. Propaganda cards bestow actions and interact with broadcast
        stations to further each nation’s agenda. Controlling multiple propaganda of the same base
        makes broadcasting more effective.
      </PolitikParagraph>
      <PolitikParagraph>
        Propaganda cards generate a resource called support. Nations may use support to gain control
        of council seats or spend it on new propaganda to expand their political repertoire. Support
        also belongs to a political base and must be used by that base. By wielding support and
        propaganda effectively, your nation can seize victory!
      </PolitikParagraph>
      <BaseSingleIconsWrapper>{mappedBaseIcons}</BaseSingleIconsWrapper>
      <Graphic src={patriots} alt="Arms of soldiers holding flags and guns." />
    </SectionContainer>
  );
};

export default PoliticalBaseSection;
